<template>
  <div>
    <div class="header">
      <h1>Scrollmagic Rocks!</h1>
    </div>
    <section class="animation">
      <img class="paper-plane" src="@/assets/plane.png" alt="paper-plane" />
    </section>
    <div class="footer">
      <h1>Woooooo</h1>
    </div>
  </div>
</template>

<script>
import { TimelineLite, TweenLite, Power1 } from "gsap";
export default {
  name: "Home",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "Home",
      meta: [
        {
          name: "description",
          content: "Home view of " + this.$applicationName,
        },
      ],
    };
  },
  mounted() {
    const flightPath = {
      curviness: 1.25,
      autoRotate: true,
      values: [
        { x: 100, y: -20 },
        { x: 300, y: 10 },
        { x: 500, y: 100 },
        { x: 750, y: -100 },
        { x: 350, y: -50 },
        { x: 600, y: 100 },
        { x: 800, y: 0 },
        { x: window.innerWidth, y: -250 },
      ],
    };

    const tween = new TimelineLite();

    tween.add(
      TweenLite.to(".paper-plane", 1, {
        bezier: flightPath,
        ease: Power1.easeInOut,
        triggerHook: 0,
      })
    );

    const scene = this.$scrollmagic
      .scene({
        triggerElement: ".animation",
        duration: 5000,
      })
      .setTween(tween)
      .setPin(".animation");
    // .addIndicators({ name: "2 (duration: 300)" });

    this.$scrollmagic.addScene(scene);
  },
};
</script>

<style scoped>
.header,
.footer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
}

.header h1 {
  font-size: 60px;
}

.footer h1 {
  font-size: 60px;
}

.animation {
  height: 100vh;
  background-image: linear-gradient(
    45deg,
    #ff9a9e 0%,
    #fad0c4 99%,
    #fad0c4 100%
  );
  position: relative;
  overflow: hidden;
}
.paper-plane {
  height: 100px;
  position: absolute;
  top: 50%;
  left: 0%;
}
</style>
