<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  metaInfo() {
    return {
      // all titles will be injected into this template
      titleTemplate: "%s | " + this.$applicationName,
    };
  },
};
</script>
<style>
::-webkit-scrollbar {
  width: 0;
}
</style>
