import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import Meta from "vue-meta";
import VueScrollmagic from "vue-scrollmagic";

//Scrollmagic
Vue.use(VueScrollmagic, {
  vertical: true,
  globalSceneOptions: {
    triggerHook: "onLeave",
    // duration: "100%"
  },
  loglevel: 2,
  refreshInterval: 100,
});

//Meta
Vue.use(Meta);

Vue.prototype.$applicationName = "Scrollmagic";

Vue.config.productionTip = false;

Vue.prototype.$dev = Vue.config.devtools;

const store = Vue.observable({
  notifications: [],
  addNotification(notification) {
    store.notifications.push(notification);
  },
  getAndRemoveNotification() {
    return store.notifications.shift();
  },
});

Vue.prototype.$globalState = store;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
