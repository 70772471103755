export default {
  primary: {
    base: "#e8e7ed",
  },
  secondary: {
    base: "#13d389",
  },
  error: {
    base: "#B00020",
  },
  warning: {
    base: "#FFC400",
  },
  info: {
    base: "#03A9F4",
  },
  success: {
    base: "#009649",
  },
  pending: {
    base: "#929292",
  },
  background: {
    base: "#f5f5fb",
  },
};
